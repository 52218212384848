import React, { Component } from "react";
import { defaultTheme } from "@agBoxUiKit";
import { Button, ExternalLink } from "@agBoxUiKit/core/components";
import {
  SideBarContainer,
  SideBarShade,
  SideBarWrapper
} from "@agBoxUiKit/core/layout";
import Pagination from "../Pagination";
import {
  decodeComputedString,
  isNullOrUndefined,
  isValidJSON
} from "../../App/utils";
import {
  ADMIN_CONSOLE_URL,
  AGBOX_ASSETS_DEFAULT_URL,
  CONTACT_SUPPORT_URL,
  DEFAULT_LANGUAGE,
  HELP_CENTRE_URL,
  LANGUAGE_FILE_PREFIX,
  LOADER_SIZE_SMALL,
  MINIMUM_SEARCH_STRING_LENGTH,
  SEARCH_BY_LOCATION,
  SEARCH_BY_NAME,
  SEARCH_TIMEOUT,
  URL_DELIMITER_ORG,
  URL_DELIMITER_PROP,
  URL_DELIMITER_PROP_GROUP,
  URL_DELIMITER_ROLE,
  URL_DELIMITER_SELECT_ORG,
  URL_DELIMITER_WORKFLOW,
  PROPERTY_SELECTOR_DEFAULT_PAGINATION_OPTIONS
} from "../../constants";
import SidebarComponent from "@agBoxUiKit/core/components/SidebarComponent/SidebarComponent";
import { Icon } from "@agBoxUiKit/core";
import { Loader, Modal } from "../../AgBoxUIKit/core";
import {
  ContentContainer,
  SingleInputWrapper
} from "../../AgBoxUIKit/plugin/components";
import SelectInput from "../../AgBoxUIKit/plugin/components/FeatureDetailsComponent/subcomponents/Inputs/SelectInput";

export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.sidebarRef = React.createRef();
    this.mouseOverTimeout = null;
    this.searchTimeOut = null;
    this.state = {
      propertyPage: 1,
      propertySearchTerm: "",
      propertySuggestionBoxOpen: false,
      propertySelectorOpen: false,
      sidebarOpen: false,
      showUserPreferenceModal: false,
      updatedUserPreferences: {}
    };
  }

  componentDidMount() {
    document.addEventListener("keyup", this.onKeyup);
    document.addEventListener("click", this.handleClickOutside, {
      capture: true
    });
    this.loadProperties();
    this.loadPropertyGroups();
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.onKeyup);
    document.removeEventListener("click", this.handleClickOutside, {
      capture: true
    });
  }

  componentDidUpdate(prevProps) {
    const { clearLocationSearchGraphics } = this.props;
    if (prevProps.orgId !== this.props.orgId && this.props.orgId) {
      clearLocationSearchGraphics();
      this.loadProperties();
      this.loadPropertyGroups();
      this.setState({
        propertyPage: 1,
        propertySearchTerm: "",
        updatedUserPreferences: {}
      });
    }
  }

  loadProperties = () => {
    const { orgId, loadProperties } = this.props;
    if (orgId) loadProperties(orgId);
  };

  loadPropertyGroups = () => {
    const { orgId, loadPropertyGroups } = this.props;
    if (orgId) loadPropertyGroups(orgId);
  };

  getToggleSidebar = () => {
    const { selectedOrganisation } = this.props;
    const userToggleSidebar = this.getUserPreferenceValue("toggleSidebar");
    if (userToggleSidebar !== null) return userToggleSidebar;
    else if (
      selectedOrganisation &&
      selectedOrganisation.preferences &&
      selectedOrganisation.preferences.toggleSidebar
    ) {
      return true;
    }
    return false;
  };

  openSidebarOnIconClick = () => {
    const { sidebarOpen } = this.state;
    if (!sidebarOpen) this.openSidebar();
  };

  handleTabbingKeys = (e) => {
    const { sidebarOpen } = this.state;
    if (this.sidebarRef.current.contains(e.target) && !sidebarOpen) {
      this.openSidebar();
    } else if (sidebarOpen && !this.sidebarRef.current.contains(e.target)) {
      this.closeSidebar();
    } else return;
  };

  onKeyup = (e) => {
    const { sidebarOpen } = this.state;
    if (e.key === "Tab") {
      this.handleTabbingKeys(e);
    } else if (e.key === "Escape" && sidebarOpen) {
      this.closeSidebar();
    } else return;
  };

  handleClickOutside = (e) => {
    const { sidebarOpen } = this.state;
    if (this.sidebarRef.current.contains(e.target) || !sidebarOpen) return;
    this.closeSidebar();
  };

  getLanguageLabel = (label, data) => {
    const { labels } = this.props;
    return decodeComputedString(labels[label], data);
  };

  getUserName = () => {
    const { firstName, lastName } = this.props;
    return [firstName, lastName].filter((item) => item).join(" ");
  };

  handleChangeOrg = () => {
    window.location.hash = URL_DELIMITER_SELECT_ORG;
  };

  getLoadingOrg = () => {
    const { loadingOrg } = this.props;
    return loadingOrg;
  };

  handleSignOut = () => {
    const { invalidateAuthentication } = this.props;
    invalidateAuthentication();
  };

  getEnableUserPreferences = () => {
    const { selectedOrganisation } = this.props;
    if (!selectedOrganisation || !selectedOrganisation.preferences)
      return false;
    return selectedOrganisation.preferences.enablePreferences === true;
  };

  getProfileDropDownOptions = () => {
    return [
      {
        disabled: true,
        label: this.getUserName(),
        icon: {
          type: "profile",
          bgHeight: "3.1em",
          bgWidth: "2.5em",
          iconHeight: "1.75em",
          iconWidth: "1.75em"
        },
        styletype: "profile",
        Component: Button
      },
      {
        label: this.getLanguageLabel("HELP_CENTER_LABEL"),
        icon: {
          type: "help-centre",
          bgHeight: "2.5em",
          bgWidth: "2.5em",
          iconHeight: "1.5em",
          iconWidth: "1.5em"
        },
        labelIcon: {
          type: "external-link",
          bgHeight: "0.8em",
          bgWidth: "0.8em",
          iconHeight: "1em",
          iconWidth: "1em"
        },
        type: "sidebarLink",
        Component: ExternalLink,
        url: HELP_CENTRE_URL,
        target: "_blank"
      },
      {
        label: this.getLanguageLabel("CONTACT_SUPPORT_LABEL"),
        icon: {
          type: "info",
          bgHeight: "2.5em",
          bgWidth: "2.5em",
          iconHeight: "2.5em",
          iconWidth: "2.5em"
        },
        labelIcon: {
          type: "external-link",
          bgHeight: "0.8em",
          bgWidth: "0.8em",
          iconHeight: "1em",
          iconWidth: "1em"
        },
        type: "sidebarLink",
        Component: ExternalLink,
        url: CONTACT_SUPPORT_URL,
        target: "_blank"
      },
      {
        label: this.getLanguageLabel("ADMIN_CONSOLE_LABEL"),
        icon: {
          type: "manage-organisation",
          bgHeight: "2.5em",
          bgWidth: "2.5em",
          iconHeight: "2.5em",
          iconWidth: "2.5em"
        },
        labelIcon: {
          type: "external-link",
          bgHeight: "0.8em",
          bgWidth: "0.8em",
          iconHeight: "1em",
          iconWidth: "1em"
        },
        type: "sidebarLink",
        Component: ExternalLink,
        url: ADMIN_CONSOLE_URL,
        target: "_blank",
        hidden: !this.getHasAdminAccess()
      },
      {
        label: this.getLanguageLabel("UPDATE_PREFERENCES_LABEL"),
        icon: {
          type: "settings",
          bgHeight: "2.5em",
          bgWidth: "2.5em",
          iconHeight: "1.75em",
          iconWidth: "1.75em"
        },
        styletype: "profile",
        Component: Button,
        onClick: this.toggleShowUserPreferenceModal,
        hidden: !this.getEnableUserPreferences()
      },
      {
        onClick: this.handleChangeOrg,
        disabled: this.getLoadingOrg(),
        label: this.getLanguageLabel("CHANGE_ORG_LABEL"),
        icon: {
          type: "change-organisation",
          bgHeight: "2.5em",
          bgWidth: "2.5em",
          iconHeight: "1.5em",
          iconWidth: "1.5em"
        },
        styletype: "orgSubMenuController",
        Component: Button
      },
      {
        onClick: this.handleSignOut,
        disabled: false,
        label: this.getLanguageLabel("SIGN_OUT_LABEL"),
        icon: {
          type: "sign-out",
          bgHeight: "2.5em",
          bgWidth: "2.5em",
          iconHeight: "1.5em",
          iconWidth: "1.5em"
        },
        styletype: "orgSubMenuController",
        Component: Button
      }
    ].filter((listItem) => !listItem.hidden);
  };

  getPropertyHomeButtonTitle = () => {
    const {
      selectedProperty,
      selectedPropertyGroup,
      labels: { SELECT_A_PROPERTY_LABEL }
    } = this.props;
    if (selectedProperty) return selectedProperty.title;
    else if (selectedPropertyGroup) return selectedPropertyGroup.title;
    else return SELECT_A_PROPERTY_LABEL;
  };

  handleClickHomeButton = () => {
    const { selectPropertyHome } = this.props;
    selectPropertyHome();
  };

  searchByAddressEnabled = () => {
    const { selectedOrganisation } = this.props;
    if (!selectedOrganisation || !selectedOrganisation.preferences)
      return false;
    return selectedOrganisation.preferences.searchByAddressEnabled === true;
  };

  getPropertySearchTypes = () => {
    const {
      labels: { NAME_LABEL, SEARCH_BY_LOCATION_LABEL }
    } = this.props;
    const searchTypes = [
      {
        value: SEARCH_BY_NAME,
        title: NAME_LABEL
      }
    ];
    if (this.searchByAddressEnabled())
      return [
        ...searchTypes,
        {
          value: SEARCH_BY_LOCATION,
          title: SEARCH_BY_LOCATION_LABEL
        }
      ];
    else return searchTypes;
  };

  handleUpdateSearchBy = (attr, value) => {
    const { setSearchPropertiesBy, clearLocationSearchGraphics } = this.props;
    this.setState({
      propertyPage: 1
    });
    this.handleClearSearchTerm();
    setSearchPropertiesBy(value);
    clearLocationSearchGraphics();
  };

  handleUpdateSearchTerm = (propertySearchTerm) => {
    if (this.searchTimeOut) {
      clearInterval(this.searchTimeOut);
    }
    this.setState({ propertySearchTerm, searching: true }, () => {
      if (
        propertySearchTerm.length >= MINIMUM_SEARCH_STRING_LENGTH ||
        propertySearchTerm === ""
      ) {
        this.searchTimeOut = setTimeout(() => {
          this.setState({
            propertyPage: 1
          });
          const { updatePropertiesSearch } = this.props;
          updatePropertiesSearch(propertySearchTerm);
        }, SEARCH_TIMEOUT);

        this.setState({ propertySuggestionBoxOpen: true });
      }
    });
  };

  handleClearSearchTerm = () => {
    this.handleUpdateSearchTerm("");
  };

  propertySearchSuggestions = () => {
    const { propertySearchSuggestions } = this.props;
    return propertySearchSuggestions ? propertySearchSuggestions : [];
  };

  selectedPropertySearchSuggestion = () => {
    const { selectedPropertySearchSuggestion } = this.props;
    return selectedPropertySearchSuggestion;
  };

  handleSelectSuggestion = (suggestion) => {
    const { setSelectedPropertySearchSuggestion } = this.props;
    setSelectedPropertySearchSuggestion(suggestion);
    this.setState({
      propertySearchTerm: suggestion.text,
      propertySuggestionBoxOpen: false
    });
  };

  getPropertySearchInputLabel = () => {
    const { searchPropertiesBy } = this.props;
    return searchPropertiesBy && searchPropertiesBy === SEARCH_BY_LOCATION
      ? this.getLanguageLabel("SEARCH_FOR_PROPERTY_BY_LOCATION_LABEL")
      : this.getLanguageLabel("SEARCH_FOR_PROPERTY_BY_NAME_LABEL");
  };

  handlePropertySelectorToggle = () => {
    this.setState({
      propertySelectorOpen: !this.state.propertySelectorOpen
    });
  };

  getPropertyTitle = (property) => {
    const { propertySelectorLabel } = this.props;
    const { officialName, title, propId } = property;
    if (propertySelectorLabel) {
      const cleansedProperty = Object.keys(property)
        .map((x) => {
          return {
            [x]: property[x] === null ? "" : property[x]
          };
        })
        .reduce((a, b) => ({ ...a, ...b }), {});
      return decodeComputedString(propertySelectorLabel, cleansedProperty);
    }

    if (title) return title;
    else if (officialName) return officialName;
    else if (propId) return propId;
    else return "";
  };

  handleClickProperty = (propId) => {
    const {
      abortRequest,
      resetVisibleLayers,
      clearWorkflow,
      selectedProperty,
      selectedPropertyGroup,
      selectingProperty,
      selectingPropertyGroup,
      unloadProperty
    } = this.props;
    if (
      selectedProperty ||
      selectedPropertyGroup ||
      selectingProperty ||
      selectingPropertyGroup
    ) {
      abortRequest();
      clearWorkflow();
      unloadProperty(propId);
    }

    this.handlePropertySelectorToggle();
    if (window.location.pathname.includes(URL_DELIMITER_WORKFLOW)) {
      return resetVisibleLayers();
    }
  };

  handleSelectPage = (pageInfo) => {
    const { offset, limit, page } = pageInfo;
    const { updatePropertiesPagination } = this.props;
    this.setState({
      propertyPage: page
    });
    updatePropertiesPagination(offset, limit);
  };

  noPropertyResultsLabel = () => {
    const {
      searchPropertiesBy,
      availableProperties = [],
      labels: { NO_MATCHING_PROPERTIES_LABEL, NO_MATCHING_SUGGESTIONS_LABEL }
    } = this.props;
    const { propertySearchTerm } = this.state;
    if (!propertySearchTerm || availableProperties.length) return "";
    else if (searchPropertiesBy === SEARCH_BY_LOCATION) {
      if (
        isValidJSON(propertySearchTerm) &&
        Array.isArray(JSON.parse(propertySearchTerm))
      )
        return NO_MATCHING_PROPERTIES_LABEL;
      else if (!this.propertySearchSuggestions().length) {
        return NO_MATCHING_SUGGESTIONS_LABEL;
      }
    }
    return NO_MATCHING_PROPERTIES_LABEL;
  };

  setZoomToSearchResults = (e) => {
    const { setZoomToSearchResults } = this.props;
    setZoomToSearchResults(e.target.checked);
  };

  getPropertyIsFavorite = (property) => {
    const { getPropertyFavorites, orgId, userId } = this.props;
    const favourites = getPropertyFavorites(orgId, userId);
    if (!favourites) return property.isFavorite === true;
    return favourites.includes(property.propId);
  };

  hasPropertyFavorites = () => {
    const { selectedOrganisation } = this.props;
    if (!selectedOrganisation || !selectedOrganisation.preferences)
      return false;
    return (
      selectedOrganisation.preferences.propertyFavoritesEnabled === true ||
      isNullOrUndefined(
        selectedOrganisation.preferences.propertyFavoritesEnabled
      )
    );
  };

  getPropertyListItems = () => {
    const {
      availableProperties = [],
      orgId,
      propertiesSearchError,
      selectedProperty
    } = this.props;
    const message = this.noPropertyResultsLabel();

    const url = new URL(window.location.href);
    const pathSegments = url.pathname.split("/");
    const propIdFromUrl = pathSegments[pathSegments.indexOf("prop") + 1];
    if (propertiesSearchError) {
      return [
        {
          label: propertiesSearchError,
          id: "error"
        }
      ];
    } else if (message) {
      return [
        {
          label: message,
          id: "message"
        }
      ];
    } else
      return availableProperties.map((property) => ({
        id: property.propId,
        url:
          selectedProperty?.propId === property.propId
            ? `/${URL_DELIMITER_ORG}/${orgId}/`
            : `/${URL_DELIMITER_ORG}/${orgId}/${URL_DELIMITER_PROP}/${property.propId}`,
        docTitle: property.title,
        label: this.getPropertyTitle(property),
        onClick: () => this.handleClickProperty(propIdFromUrl),
        type: "propertyListing",
        button: this.hasPropertyFavorites() ? (
          <Button
            styletype={"property-favourite"}
            title={this.getLanguageLabel(
              this.getPropertyIsFavorite(property)
                ? "UNFAVOURITE_PROPERTY_LABEL"
                : "FAVOURITE_PROPERTY_LABEL"
            )}
            onClick={() => this.handleUpdatePropertyFavourites(property)}
          >
            <Icon
              type={`star${
                this.getPropertyIsFavorite(property) ? "" : "-empty"
              }`}
              iconWidth={"1.2em"}
              iconHeight={"1.2em"}
              iconColor={defaultTheme.agWhite}
            />
          </Button>
        ) : null
      }));
  };

  handleUpdatePropertyFavourites = (property) => {
    const { updateFavouriteProperties, orgId } = this.props;
    const { propId } = property;
    const isFavorite = this.getPropertyIsFavorite(property);
    updateFavouriteProperties(orgId, {
      adds: isFavorite ? [] : [propId],
      removes: isFavorite ? [propId] : []
    });
  };

  updatePaginationLimit = (limit) => {
    const { propertyPage } = this.state;
    const {
      propertiesPagination: { total },
      updatePropertiesPagination
    } = this.props;
    const maxPage = Math.ceil(total / limit);
    const newPage = propertyPage > maxPage ? maxPage : propertyPage;
    const offset = limit * (newPage - 1);
    this.setState({
      propertyPage: newPage
    });
    updatePropertiesPagination(offset, limit);
  };

  getPropertyPagination = () => {
    const {
      loadingOrg,
      loadingProperties,
      propertiesPagination,
      propertiesSearchError,
      availableProperties = [],
      selectedOrganisation
    } = this.props;
    const { propertyPage, propertySelectorOpen } = this.state;

    const propertySelectorPaginationOptions =
      selectedOrganisation?.preference?.propertySelectorPaginationOptions ||
      PROPERTY_SELECTOR_DEFAULT_PAGINATION_OPTIONS;
    const paginationOptions = propertySelectorPaginationOptions.map(
      (option) => {
        return {
          value: option,
          title: String(option)
        };
      }
    );

    if (
      !propertySelectorOpen ||
      propertiesSearchError ||
      this.noPropertyResultsLabel() ||
      !availableProperties.length
    )
      return null;

    const loading =
      loadingOrg === true ||
      loadingProperties === true ||
      selectedOrganisation === false ||
      selectedOrganisation === null;

    return (
      <Pagination
        {...{
          ...propertiesPagination,
          onPageSelected: this.handleSelectPage,
          loading: loading,
          page: propertyPage,
          limitOptions: paginationOptions,
          onChangeLimit: this.updatePaginationLimit,
          showLimitDropdown: true
        }}
      />
    );
  };

  getPropertySelectorProps = () => {
    const {
      labels: {
        RESET_PROPERTY_LABEL,
        SEARCH_BY_LABEL,
        VIEW_DIFFERENT_PROPERTY_LABEL,
        AVAILABLE_PROPERTY_GROUPS_TITLE_LABEL,
        ZOOM_TO_SEARCH_RESULTS_LABEL
      },
      searchPropertiesBy,
      busy,
      orgId,
      selectedPropertyGroup,
      loadingProperties,
      availablePropertyGroups = [],
      loadingPropertyGroups,
      shouldZoomToSearchResults
    } = this.props;
    const {
      propertySearchTerm,
      propertySuggestionBoxOpen,
      propertySelectorOpen
    } = this.state;
    return {
      homeButton: {
        label: this.getPropertyHomeButtonTitle(),
        onClick: this.handleClickHomeButton,
        title: RESET_PROPERTY_LABEL
      },
      dropdown: {
        disabled: busy,
        label: VIEW_DIFFERENT_PROPERTY_LABEL,
        open: propertySelectorOpen,
        onClick: this.handlePropertySelectorToggle,
        lists: [
          {
            items: this.getPropertyListItems(),
            loading: loadingProperties,
            pagination: this.getPropertyPagination()
          },
          {
            title: AVAILABLE_PROPERTY_GROUPS_TITLE_LABEL,
            loading: loadingPropertyGroups,
            items: availablePropertyGroups.map((group) => ({
              id: group.groupId,
              url:
                selectedPropertyGroup?.groupId == group.groupId
                  ? `/${URL_DELIMITER_ORG}/${orgId}/`
                  : `/${URL_DELIMITER_ORG}/${orgId}/${URL_DELIMITER_PROP_GROUP}/${group.groupId}`,
              docTitle: group.title,
              label: group.title,
              onClick: this.handleClickProperty,
              type: "propertyListing",
              icon: {
                type: "group",
                bgHeight: "1.25em",
                bgWidth: "1.25em",
                iconHeight: "0.8em",
                iconWidth: "0.8em"
              }
            }))
          }
        ]
      },
      search: {
        options: this.getPropertySearchTypes(),
        onChangeSearchBy: this.handleUpdateSearchBy,
        searchByLabel: SEARCH_BY_LABEL,
        selectedSearchType: searchPropertiesBy,
        searchTerm: propertySearchTerm,
        updateSearchTerm: this.handleUpdateSearchTerm,
        suggestions: this.propertySearchSuggestions(),
        selectedSuggestion: this.selectedPropertySearchSuggestion(),
        clearSearchTerm: this.handleClearSearchTerm,
        showSuggestions: propertySuggestionBoxOpen,
        onSelectSuggestion: this.handleSelectSuggestion,
        searchInputLabel: this.getPropertySearchInputLabel(),
        zoom:
          searchPropertiesBy === SEARCH_BY_LOCATION
            ? {
                checked: shouldZoomToSearchResults,
                onChange: this.setZoomToSearchResults,
                label: ZOOM_TO_SEARCH_RESULTS_LABEL
              }
            : null
      }
    };
  };

  handleToggleProfileDropDown = () => {
    const { openProfileDropDown, closeProfileDropDown } = this.props;
    const profileDropDownOpen = this.getProfileDropDownOpen();
    profileDropDownOpen ? closeProfileDropDown() : openProfileDropDown();
  };

  getHasAdminAccess = () => {
    const { orgId, getHasAdminAccess } = this.props;
    return getHasAdminAccess(orgId);
  };

  getProfileDropDownOpen = () => {
    const { getProfileDropDownOpen } = this.props;
    return getProfileDropDownOpen;
  };

  getControllerProps = () => {
    const { selectedOrganisation, orgId } = this.props;
    const profileDropDownOpen = this.getProfileDropDownOpen();
    return {
      submenu: {
        items: this.getProfileDropDownOptions(),
        description: this.getLanguageLabel("OPEN_LABEL", {
          item: this.getLanguageLabel("MENU_LABEL")
        }),
        open: profileDropDownOpen,
        onToggle: this.handleToggleProfileDropDown
      },
      logoTitle: this.getLanguageLabel("AGBOX_LOGO_TITLE"),
      url: `/${URL_DELIMITER_ORG}/${orgId}`,
      label: selectedOrganisation ? selectedOrganisation.title : "",
      description: this.getLanguageLabel("ORGANISATION_OVERVIEW_LABEL")
    };
  };

  getOrderedWorkflows = () => {
    const { availableWorkflows, userWorkflowOrder, orgWorkflowOrder } =
      this.props;
    if (!availableWorkflows) return [];
    if (!userWorkflowOrder && !orgWorkflowOrder) return availableWorkflows;

    const workflowOrder =
      userWorkflowOrder && userWorkflowOrder.length > 0
        ? userWorkflowOrder
        : orgWorkflowOrder;

    return availableWorkflows
      .map((workflow) => ({
        ...workflow,
        index:
          workflowOrder.indexOf(workflow.workflowId) !== -1
            ? workflowOrder.indexOf(workflow.workflowId)
            : 999999
      }))
      .sort((a, b) => a.index - b.index);
  };

  deselectWorkflowUrl = () => {
    return window.location.pathname.split(URL_DELIMITER_WORKFLOW)[0];
  };

  directToWorkflowUrl = (workflowId) => {
    const path = window.location.pathname
      .replace("#error", "")
      .split(`/${URL_DELIMITER_WORKFLOW}`)[0];
    const newPath = `${path}${
      path.charAt(path.length - 1) === "/" ? "" : "/"
    }${URL_DELIMITER_WORKFLOW}/${workflowId}`;
    return newPath;
  };

  getWorkflowUrl = (workflowId) => {
    return this.isActiveWorkflow(workflowId)
      ? this.deselectWorkflowUrl()
      : this.directToWorkflowUrl(workflowId);
  };

  isActiveWorkflow = (workflowId) => {
    const { selectedWorkflowId } = this.props;
    return selectedWorkflowId === workflowId;
  };

  getWorkflowDocTitle = ({ workflowId, title }) => {
    return this.isActiveWorkflow(workflowId)
      ? this.getPropertyHomeButtonTitle()
      : title;
  };

  getThemePath = () => {
    const { selectedOrganisation } = this.props;
    return selectedOrganisation &&
      selectedOrganisation.preferences &&
      selectedOrganisation.preferences.themePath &&
      selectedOrganisation.preferences.themePath !== ""
      ? `${selectedOrganisation.preferences.themePath}/icons/`
      : `${AGBOX_ASSETS_DEFAULT_URL}/icons/`;
  };

  getWorkflowProps = () => {
    const { busy, selectingProperty } = this.props;
    return {
      loading: selectingProperty,
      loaderSize: LOADER_SIZE_SMALL,
      items: this.getOrderedWorkflows().map((workflow) => {
        const { workflowId, title, icon } = workflow;
        return {
          id: workflowId,
          active: this.isActiveWorkflow(workflowId),
          label: title,
          url: this.getWorkflowUrl(workflowId),
          docTitle: this.getWorkflowDocTitle(workflow),
          onClick: this.closeSidebar,
          icon: {
            type: icon,
            path: this.getThemePath(),
            iconHeight: "1.75em",
            iconWidth: "1.75em",
            bgWidth: defaultTheme.menuWidthClose,
            bgHeight: defaultTheme.menuWidthClose
          },
          disabled: busy,
          type: "sideBar"
        };
      })
    };
  };

  openSidebar = () => {
    this.setState({
      sidebarOpen: true
    });
  };

  closeSidebar = () => {
    const { closeProfileDropDown } = this.props;
    closeProfileDropDown();
    this.setState({
      sidebarOpen: false,
      propertySelectorOpen: false
    });
  };

  getSidebarProps = () => {
    const { sidebarOpen } = this.state;
    return {
      controller: this.getControllerProps(),
      onClick: this.openSidebarOnIconClick,
      workflows: this.getWorkflowProps(),
      propertySelector: this.getPropertySelectorProps(),
      open: sidebarOpen,
      closeButton: {
        visible: this.getToggleSidebar() ? this.state.sidebarOpen : false,
        onClick: this.closeSidebar,
        description: this.getLanguageLabel("CLOSE_SIDEBAR_LABEL")
      }
    };
  };

  onMouseOver = () => {
    if (this.state.sidebarOpen) return;
    if (this.mouseOverTimeout) {
      clearTimeout(this.mouseOverTimeout);
      this.mouseOverTimeout = null;
    }
    this.mouseOverTimeout = setTimeout(this.openSidebar, 300);
  };

  onMouseOut = () => {
    if (this.mouseOverTimeout) clearTimeout(this.mouseOverTimeout);
    if (this.getToggleSidebar()) return;
    this.closeSidebar();
  };

  toggleShowUserPreferenceModal = () => {
    const { showUserPreferenceModal } = this.state;
    this.setState({
      showUserPreferenceModal: !showUserPreferenceModal,
      updatedUserPreferences: {}
    });
  };

  getUserPreferencesForOrg = () => {
    const { getUserPreferencesForOrg, orgId } = this.props;
    return getUserPreferencesForOrg(orgId) || {};
  };

  getUserPreferenceValue = (field) => {
    const preferences = this.getUserPreferencesForOrg();
    return preferences.hasOwnProperty(field) ? preferences[field] : null;
  };

  getLanguageOptions = () => {
    return [
      {
        value: DEFAULT_LANGUAGE,
        title: "English"
      }
    ];
  };

  getSelectedLanguage = () => {
    const { updatedUserPreferences } = this.state;
    return (
      updatedUserPreferences.language ||
      this.getUserPreferenceValue("language") ||
      this.getLanguageOptions()[0].value
    );
  };

  updateUserPreferenceValue = (field, value) => {
    const { updatedUserPreferences } = this.state;
    this.setState({
      updatedUserPreferences: {
        ...updatedUserPreferences,
        [field]: value
      }
    });
  };

  getSelectedToggleSidebarValue = () => {
    const { updatedUserPreferences } = this.state;
    if (updatedUserPreferences.hasOwnProperty("toggleSidebar"))
      return (
        updatedUserPreferences.toggleSidebar === true ||
        updatedUserPreferences.toggleSidebar === "true"
      );
    return this.getToggleSidebar();
  };

  getToggleSidebarOptions = () => {
    return [
      {
        value: true,
        title: this.getLanguageLabel("TOGGLE_OPEN_LABEL")
      },
      {
        value: false,
        title: this.getLanguageLabel("AUTO_CLOSE_LABEL")
      }
    ];
  };

  getUserPreferenceForm = () => {
    return (
      <ContentContainer>
        <SingleInputWrapper>
          <SelectInput
            label={this.getLanguageLabel("LANGUAGE_LABEL")}
            value={this.getSelectedLanguage()}
            handleUpdateValue={this.updateUserPreferenceValue}
            attribute={"language"}
            options={this.getLanguageOptions()}
            defaultOptionLabel={this.getLanguageLabel(
              "DEFAULT_OPTION_VALUE_LABEL"
            )}
            isValid={true}
          />
        </SingleInputWrapper>
        <SingleInputWrapper>
          <SelectInput
            label={this.getLanguageLabel("TOGGLE_SIDEBAR_OPEN_LABEL")}
            value={this.getSelectedToggleSidebarValue()}
            handleUpdateValue={this.updateUserPreferenceValue}
            attribute={"toggleSidebar"}
            options={this.getToggleSidebarOptions()}
            defaultOptionLabel={this.getLanguageLabel(
              "DEFAULT_OPTION_VALUE_LABEL"
            )}
            isValid={true}
          />
        </SingleInputWrapper>
      </ContentContainer>
    );
  };
  getUserPrefererenceModalBody = () => {
    return this.getUpdatingUser() ? <Loader /> : this.getUserPreferenceForm();
  };

  getUpdatingUser = () => {
    const { updatingUser } = this.props;
    return updatingUser;
  };

  getUserPreferenceModalTitle = () => {
    return this.getLanguageLabel(
      this.getUpdatingUser()
        ? "UPDATING_PREFERENCES_LABEL"
        : "PREFERENCES_LABEL"
    );
  };

  updateUserPreferences = () => {
    const { updateUserPreferences, orgId, userId } = this.props;
    const language = this.getSelectedLanguage();
    const preferences = this.getUserPreferencesForOrg();
    updateUserPreferences(userId, {
      [orgId]: {
        ...preferences,
        toggleSidebar: this.getSelectedToggleSidebarValue(),
        language,
        languageFile: `${LANGUAGE_FILE_PREFIX}${language}.json`
      }
    });
  };

  getModalPrimaryAction = () =>
    this.getUpdatingUser() ? null : this.updateUserPreferences;
  getModalSecondaryAction = () =>
    this.getUpdatingUser() ? null : this.toggleShowUserPreferenceModal;

  render() {
    const { sidebarOpen, showUserPreferenceModal } = this.state;
    const { workflowPanelOpen } = this.props;
    return (
      <SideBarWrapper data-name={"SideBarWrapper"}>
        <SideBarContainer
          ref={this.sidebarRef}
          onMouseEnter={this.onMouseOver}
          onMouseLeave={this.onMouseOut}
          open={sidebarOpen}
        >
          <SidebarComponent {...this.getSidebarProps()} />
        </SideBarContainer>
        <SideBarShade
          data-name={"SideBarShade"}
          closed={!sidebarOpen}
          workflowPanelOpen={workflowPanelOpen}
        />
        <Modal
          isOpen={showUserPreferenceModal}
          title={this.getUserPreferenceModalTitle()}
          onClose={this.toggleShowUserPreferenceModal}
          body={this.getUserPrefererenceModalBody()}
          primaryButtonTitle={this.getLanguageLabel("UPDATE_PREFERENCES_LABEL")}
          primaryButtonAction={this.getModalPrimaryAction()}
          secondaryButtonTitle={this.getLanguageLabel("CANCEL_LABEL")}
          secondaryButtonAction={this.getModalSecondaryAction()}
        />
      </SideBarWrapper>
    );
  }
}
