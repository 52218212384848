import jest from "jest-mock";
import labels from "@agBoxSource/english";
import { decodeComputedString } from "../App/utils";
import {
  FEATURE_AREA_SEARCH,
  REQUEST_STATUS_COMPLETED,
  UNIT_HECTARES
} from "../constants";

export const graphicsLayer = {
  add: jest.fn(),
  remove: jest.fn(),
  addMany: jest.fn(),
  removeMany: jest.fn(),
  removeAll: jest.fn(),
  graphics: {
    items: []
  },
  title: "the graphics layer"
};

export const applyEditsMock = jest.fn();

export const mapViewMock = {
  on: jest.fn().mockReturnValue("theOnHandler"),
  goTo: jest.fn().mockName("goToMethod"),
  center: jest.fn().mockReturnValue((x) => x),
  rotation: 100,
  whenLayerView: jest.fn().mockImplementation((layer) => ({
    layer
  })),
  spatialReference: {
    toJSON: jest.fn().mockReturnValue("theSpatialReference")
  },
  hitTest: jest.fn().mockReturnValue([]),
  toMap: jest.fn().mockReturnValue("theMapPoint"),
  takeScreenshot: jest.fn().mockReturnValue({
    dataUrl: "theDataUrl"
  }),
  camera: {
    heading: 100
  },
  watch: jest.fn().mockReturnValue({ remove: jest.fn() }),
  scale: 2000,
  when: jest.fn((callback) => callback())
};

export const webMapMock = {
  allLayers: jest.fn(),
  add: jest.fn(),
  addMany: jest.fn(),
  reorder: jest.fn(),
  remove: jest.fn(),
  removeMany: jest.fn(),
  basemap: "satellite",
  layers: {
    on: jest.fn().mockReturnValue({ remove: jest.fn() }),
    add: jest.fn().mockImplementation((x) => {
      return x;
    }),
    find: jest.fn().mockReturnValue((x) => x),
    items: [],
    splice: jest.fn(),
    reorder: jest.fn(),
    findIndex: jest.fn(),
    remove: jest.fn(),
    removeMany: jest.fn()
  }
};

export const getLanguageLabel = jest.fn().mockImplementation((x, data) => {
  const label = labels[x];
  if (!label) return x;
  return decodeComputedString(label, data);
});
export const selectedWorkflow = {
  title: "the workflow"
};

export const selectedOrganisation = {
  orgId: "theOrgId"
};

export const selectedProperty = {
  propId: "thePropId",
  title: "thePropTitle"
};

export const selectedPropertyGroup = {
  extent: "some group extent"
};

export const updateWorkflowState = jest
  .fn()
  .mockImplementation((update, callback) => {
    if (callback) callback();
  });
export const updatePrintResults = jest.fn();
export const handleStartLoadingFeatures = jest.fn();
export const featureListItems = [
  {
    attributes: {
      title: "feature 1",
      objectId: 1,
      jobStatus: "scheduled"
    },
    geometry: {
      type: "polygon"
    },
    layer: {
      title: "cropPoly",
      geometryType: "polygon"
    },
    clone: jest.fn().mockReturnThis()
  },
  {
    attributes: {
      title: "feature 2",
      customData: JSON.stringify({ status: { value: "approved" } }),
      objectId: 2
    },
    geometry: {
      type: "point"
    },
    layer: {
      title: "cropPoint",
      geometryType: "polygon"
    },
    clone: jest.fn().mockReturnThis()
  }
];
export const featureLayer = {
  title: "theLayer",
  renderer: {
    uniqueValueInfos: [],
    defaultSymbol: {
      color: {
        toString: jest.fn().mockReturnValue("red")
      }
    }
  },
  layerId: 1,
  graphics: { items: [{}] },
  url: "theUrl",
  fields: [],
  when: jest.fn().mockImplementation((x) => x()),
  queryFeatureCount: jest.fn().mockResolvedValue(2),
  createQuery: jest.fn().mockReturnValue({}),
  applyEdits: jest.fn(),
  queryExtent: jest.fn().mockResolvedValue({
    extent: {
      xmin: 1,
      ymin: 1,
      xmax: 1,
      ymax: 1,
      clone: jest.fn().mockReturnThis(),
      expand: jest.fn().mockReturnThis()
    }
  }),
  queryFeatures: jest.fn().mockResolvedValue({
    features: featureListItems
  }),
  removeAll: jest.fn(),
  add: jest.fn(),
  remove: jest.fn()
};
export const getLayerByTitle = jest.fn().mockReturnValue(featureLayer);
export const listTitle = jest.fn().mockReturnValue("title");
export const listSubTitles = jest.fn().mockReturnValue([
  {
    field: "category",
    label: "Type: "
  }
]);
export const featureListLimit = jest.fn().mockReturnValue(10);
export const handleChangePerPageValue = jest.fn();
export const handlePagination = jest.fn();
export const getDomainValues = jest.fn().mockReturnValue({
  category: [
    {
      value: "cat1",
      title: "Cat 1"
    },
    {
      value: "cat2",
      title: "Cat 2"
    }
  ],
  unit: [
    {
      value: "g",
      title: "Grams"
    },
    {
      value: "mL"
    }
  ],
  jobStatus: [
    {
      value: "draft"
    },
    {
      value: "submitted"
    }
  ],
  allocatedUser: [
    { title: "james", value: "james" },
    { title: "greg", value: "greg" },
    { title: "No person assigned", value: "No person assigned" }
  ]
});
export const navigate = jest.fn();
export const setVisibleLayers = jest.fn();
export const queryFeaturesByLayerTitle = jest
  .fn()
  .mockResolvedValue(featureListItems);
export const removeLayerByTitle = jest.fn();
export const interactiveLayer = {
  remove: jest.fn()
};
export const zoomToFeatures = jest.fn();
export const zoomToPropertyBoundary = jest.fn();
export const makeLayerInteractive = jest.fn().mockReturnValue(interactiveLayer);
export const featureDetails = {
  attributes: {
    title: "theTitle",
    block: "theBlock",
    objectId: 20,
    category: "Kiwifruit",
    variety: "GA",
    customData: {
      width: { value: 10 },
      length: { value: 10 }
    }
  },
  layer: { title: "layerTitle" },
  geometry: {
    type: "polygon"
  },
  clone: jest.fn().mockReturnThis()
};
export const queryFeatureDetails = jest
  .fn()
  .mockResolvedValue([featureDetails]);
export const handleSaveUpdateFeature = jest
  .fn()
  .mockResolvedValue([{ objectId: 1 }]);
export const handleAddNewFeature = jest
  .fn()
  .mockResolvedValue([{ objectId: 1 }]);
export const settings = {
  hiddenAttributes: ["objectId"],
  domainValues: {},
  nonEditableAttributes: [],
  validationRules: {}
};
export const getDetailsSettings = jest.fn().mockReturnValue(settings);
export const getFeatureTitle = jest.fn().mockReturnValue("theTitle");
export const calculateTotalArea = jest.fn().mockResolvedValue(20);
export const totalAreaString = jest.fn().mockReturnValue("20 sqm");
export const defaultRootUrl = jest.fn().mockReturnValue("defaultUrl");
export const hasPermission = jest.fn().mockReturnValue(true);
export const whenReadOnly = jest.fn().mockReturnValue(false);
export const slugInformation = jest.fn().mockReturnValue({
  objectId: 1,
  layerTitle: "theLayer"
});
export const objectIdField = "objectId";
export const propertyFeature = {
  attributes: {
    title: "property 1",
    customData: JSON.stringify({
      haywardTarget: {
        value: 10
      },
      sungoldTarget: {
        value: null
      }
    })
  },
  geometry: {
    extent: {
      xmin: 1,
      ymin: 1,
      xmax: 2,
      ymax: 2
    },
    spatialReference: { wkid: 1234 }
  },
  clone: jest.fn().mockReturnThis()
};
export const getPropertyFeatures = jest
  .fn()
  .mockResolvedValue([propertyFeature]);
export const errorNotification = jest.fn();
export const getSelectedSearchType = jest
  .fn()
  .mockReturnValue(FEATURE_AREA_SEARCH);
export const handleUpdateSearchType = jest.fn();
export const getSearchText = jest.fn().mockReturnValue("text");
export const handleUpdateTextSearch = jest.fn();
export const setWorkflowSize = jest.fn();
export const getSearchTypes = jest.fn().mockReturnValue([]);
export const resetSearchQuery = jest.fn();
export const handleUpdateSearchQueries = jest.fn();
export const workflowFilters = jest.fn().mockReturnValue([]);
export const updateFilters = jest.fn();
export const updateFeatureListItem = jest.fn();
export const getQueryExpression = jest.fn().mockReturnValue("the expression");
export const successNotification = jest.fn();
export const handleDeleteFeature = jest.fn();
export const getLayerName = jest.fn().mockImplementation((x) => x);
export const firstPerPageOption = jest.fn().mockReturnValue(10);
export const createNewFeature = jest.fn().mockReturnValue(featureDetails);
export const noGraphicsModal = jest.fn().mockReturnValue("a modal");
export const getQueryGeometry = jest.fn().mockResolvedValue("the geometry");
export const calculateLength = jest.fn().mockResolvedValue(10);
export const whenMissingValidation = jest.fn().mockReturnValue(false);
export const createGraphicsLayer = jest.fn().mockReturnValue(graphicsLayer);
export const createFeatureLayer = jest.fn().mockReturnValue(featureLayer);
export const getMultipleLayersByTitles = jest
  .fn()
  .mockReturnValue([featureLayer, featureLayer, featureLayer]);
export const searchIsActive = jest.fn().mockReturnValue(false);
export const getGeometryEmphasis = jest.fn().mockReturnValue([]);
export const getDefaultValues = jest.fn().mockReturnValue({});
export const setLayerFilterByTitle = jest.fn();
export const errorModalBody = jest.fn().mockReturnValue("some modal body");

export const areaUnit = jest.fn().mockReturnValue(UNIT_HECTARES);
export const getAreaDisplayUnit = jest.fn().mockReturnValue("sqm");
export const getMatchingUnit = jest.fn().mockReturnValue({
  shortname: "ha",
  unit: "hectares"
});
export const getExpiredEmphasis = jest
  .fn()
  .mockReturnValue("theExpiredEmphasis");
export const addLayersToWebMap = jest.fn();
export const clearFeatureListItems = jest.fn();
export const clearLabelGraphics = jest.fn();
export const createGraphicsLabels = jest.fn();
export const getNotConvertedToLocaleString = jest.fn().mockReturnValue([]);
export const setBasemap = jest.fn();
export const basemapId = "satellite";
export const notification = jest.fn();
export const setLayerVisibilityByTitle = jest.fn();
export const removeManyLayersByTitles = jest.fn();
export const getListPage = jest.fn().mockReturnValue(2);
export const createSelectableGraphics = jest
  .fn()
  .mockReturnValue(featureListItems);
export const handleSelectGraphic = jest.fn().mockReturnValue([]);
export const resetGraphicsWorkflowState = jest.fn();
export const createLabelsLayer = jest.fn().mockResolvedValue(graphicsLayer);
export const getUnits = jest.fn().mockReturnValue({
  theField: "theUnit"
});
export const loadFeatures = jest.fn().mockResolvedValue(featureListItems);
export const sendActionLog = jest.fn();
export const nextFeature = {
  attributes: {
    title: "theTitle",
    objectId: 1
  },
  layer: {
    title: "theLayerTitle"
  }
};
export const getNextFeature = jest.fn().mockReturnValue(nextFeature);
export const addGraphicToWorkflowState = jest.fn();

export const updateSelectedPropertyDetails = jest.fn();
export const updateAvailableProperty = jest.fn();
export const hasSketchGraphic = jest.fn();
export const whenNoSelectedGraphics = jest.fn().mockReturnValue(false);
export const updateListItemsWithArea = jest.fn();
export const addGraphicsToGraphicsLayer = jest.fn();
export const getCustomAttributes = jest.fn().mockReturnValue({
  notchVariety: {
    type: "string"
  }
});

export const getSelectedGraphic = jest.fn().mockReturnValue({
  attributes: {
    variety: "PK",
    [objectIdField]: "theId"
  },
  geometry: {
    centroid: "theCentroid"
  },
  clone: jest.fn().mockReturnValue({
    attributes: {
      variety: "PK",
      [objectIdField]: "theId"
    }
  })
});
export const isGraphicSelected = jest.fn().mockReturnValue(false);
export const getDisplayTitles = jest.fn().mockReturnValue({
  adjuvants: "Adjuvant title"
});
export const getAttributeDomainValues = jest.fn().mockReturnValue([
  {
    value: "some note"
  }
]);
export const setIsSearchingFeatures = jest.fn();
export const updateLayerRendererWithColors = jest.fn();
export const initialiseListView = jest.fn();
export const setInitialQuerySettings = jest.fn();
export const getDataSetFeaturesByGeometry = jest
  .fn()
  .mockReturnValue(featureLayer);
export const resetAttachmentWorkflowState = jest.fn();
export const newFeatureAttachmentInfos = jest.fn();
export const addAttachmentsToFeature = jest.fn().mockReturnValue([
  {
    response: "Good",
    error: false
  }
]);
export const getAttachmentsByFeatureId = jest.fn();
export const attachmentInfos = jest.fn();
export const deleteAttachments = jest.fn();
export const availablePropertyGroups = jest.fn().mockReturnValue([
  {
    groupId: "theGroupId",
    orgId: "theOrgId",
    title: "theGroupTitle",
    roleId: "theRoleId",
    description: "theDescription",
    data: "theData"
  }
]);
export const getSelectedPropertyUsers = jest.fn().mockReturnValue(["newUsers"]);
export const projectToSpatialReference = jest
  .fn()
  .mockReturnValue(["projectedSpatialReference"]);
export const getListSortOrder = jest.fn().mockReturnValue("asc");
export const core = {
  workflowReady: true,
  isSearching: false,
  setInitialQuerySettings,
  initialiseListView,
  graphicsLayer,
  isGraphicSelected,
  getSelectedGraphic,
  loadFeatures,
  selectedWorkflow,
  selectedOrganisation,
  selectedProperty,
  selectedPropertyGroup,
  getLanguageLabel,
  updateWorkflowState,
  updatePrintResults,
  handleStartLoadingFeatures,
  featureListItems,
  getLayerByTitle,
  listTitle,
  listSubTitles,
  featureListLimit,
  handleChangePerPageValue,
  handlePagination,
  getDomainValues,
  setVisibleLayers,
  queryFeaturesByLayerTitle,
  removeLayerByTitle,
  makeLayerInteractive,
  webMap: webMapMock,
  zoomToFeatures,
  zoomToPropertyBoundary,
  queryFeatureDetails,
  handleSaveUpdateFeature,
  getDetailsSettings,
  getFeatureTitle,
  calculateTotalArea,
  defaultRootUrl,
  getPropertyFeatures,
  slugInformation,
  objectIdField,
  errorNotification,
  getSelectedSearchType,
  handleUpdateSearchType,
  getSearchText,
  handleUpdateTextSearch,
  handleAddNewFeature,
  setWorkflowSize,
  getSearchTypes,
  resetSearchQuery,
  handleUpdateSearchQueries,
  workflowFilters,
  updateFilters,
  updateFeatureListItem,
  getQueryExpression,
  totalAreaString,
  successNotification,
  handleDeleteFeature,
  mapView: mapViewMock,
  has3D: false,
  getLayerName,
  firstPerPageOption,
  createNewFeature,
  noGraphicsModal,
  getQueryGeometry,
  calculateLength,
  whenMissingValidation,
  createGraphicsLayer,
  createFeatureLayer,
  getMultipleLayersByTitles,
  searchIsActive,
  getGeometryEmphasis,
  getDefaultValues,
  setLayerFilterByTitle,
  errorModalBody,
  areaUnit,
  getAreaDisplayUnit,
  getMatchingUnit,
  getExpiredEmphasis,
  addLayersToWebMap,
  clearFeatureListItems,
  clearLabelGraphics,
  createGraphicsLabels,
  getNotConvertedToLocaleString,
  setBasemap,
  basemapId,
  notification,
  setLayerVisibilityByTitle,
  removeManyLayersByTitles,
  getListPage,
  createSelectableGraphics,
  handleSelectGraphic,
  resetGraphicsWorkflowState,
  createLabelsLayer,
  getUnits,
  sendActionLog,
  getNextFeature,
  addGraphicToWorkflowState,
  updateSelectedPropertyDetails,
  updateAvailableProperty,
  hasSketchGraphic,
  whenNoSelectedGraphics,
  updateListItemsWithArea,
  addGraphicsToGraphicsLayer,
  getCustomAttributes,
  getDisplayTitles,
  getAttributeDomainValues,
  featureDetails,
  settings,
  setIsSearchingFeatures,
  getDataSetFeaturesByGeometry,
  resetAttachmentWorkflowState,
  newFeatureAttachmentInfos,
  addAttachmentsToFeature,
  getAttachmentsByFeatureId,
  attachmentInfos,
  deleteAttachments,
  updateLayerRendererWithColors,
  availablePropertyGroups,
  getSelectedPropertyUsers,
  projectToSpatialReference,
  getListSortOrder
};

export const permissions = {
  hasPermission,
  whenReadOnly
};

export const preferences = {
  featureListLimit: 100000,
  units: {
    areaUnits: [
      {
        unit: "hectares",
        shortname: "ha"
      },
      {
        unit: "square-meters",
        shortname: "sqm"
      }
    ],
    preferredAreaUnit: "hectares"
  },
  layerLabelsJSON: {
    layerTitle: "the labeling info"
  },
  layerRenderersJSON: {
    layerTitle: {
      type: "uniqueValue"
    }
  }
};

export const context = {
  property: {
    extent: "thePropertyExtent",
    propId: "thePropId",
    officialName: "KPIN:1234",
    title: "thePropTitle"
  },
  user: {
    token: "theToken",
    firstName: "theFirstName",
    lastName: "theLastName",
    userId: "theUserId"
  },
  organisation: {
    orgId: "theOrgId",
    preferences,
    renderers: {
      layerTitle: "the renderer"
    }
  },
  propertyGroup: {
    properties: [
      {
        objectId: 1
      }
    ]
  },
  spatialReference: { wkid: 2193 }
};
export const workflowAbortController = {
  abort: jest.fn(),
  signal: {
    aborted: false
  }
};

export const workflowCommand = {};

export const workflowState = {
  listTotal: 50,
  listLimit: 10,
  listPage: 2,
  workflowAbortController,
  filters: [
    {
      field: "category"
    }
  ],
  domainValues: {
    variety: [
      {
        title: "Hayward",
        value: "HW"
      },
      {
        title: "SunGold",
        value: "GA"
      }
    ]
  },
  emphasisColors: {
    draft: "pink",
    submitted: "green",
    scheduled: "pink"
  }
};

export const startRequest = jest.fn();
export const requests = {
  startRequest,
  TheThing: {
    status: REQUEST_STATUS_COMPLETED,
    results: [
      {
        title: "the title"
      }
    ]
  }
};
export const orgId = "theOrgId";
export const propId = "thePropId";
export const groupId = "theGroupId";

export const imageryTileMock =
  "iVBORw0KGgoAAAANSUhEUgAAAgAAAAIACAYAAAD0eNT6AAAgAElEQVR4nES7Z7Rl53nf99u9nL1PP+f2NvdOL5gGzIAAUQkQJEiKEpsYypGdKJZkfYjlWLEiJwpXHNsriW3JtuxoqSwxJC2RIikWEIRAEiBAlMFgMIPp5c7cub2efs4+Z/e98+Eiyudd1rv2et/9/P+/5/8I/+yf/G762ONnee+9S+j5LPeWt9l3fD+ffPrj/OlX/4ynD42zslmj4asMZXT6/R7NTpdUlEgyCgOvxUD3KGkmeWMcgNDxKeaKBHGAkMb0gz75kkU/qiGis7bSppArMVYd5+78DXKmxcKteX7xU7/I9as32NhYY+/egyzeX6E/";

export const featureSlug = "1-theLayer";
export const graphChartType = jest.fn().mockReturnValue("pie");
export const graphColorScale = jest.fn().mockReturnValue(["gray", "orange"]);
export const graphData = jest.fn().mockReturnValue([
  {
    x: "varietyOne",
    y: 1
  },
  {
    x: "varietyTwo",
    y: 1
  }
]);
export const graphDesc = jest.fn().mockReturnValue("theDescription");
export const graphDisplayOptions = jest.fn().mockReturnValue([
  {
    value: "category",
    title: "category"
  }
]);
export const graphTitle = jest.fn().mockReturnValue("theTitle");
export const updateRenderer = jest.fn();
export const graph = {
  graphChartType,
  graphColorScale,
  graphData,
  graphDesc,
  graphDisplayOptions,
  graphTitle,
  updateRenderer
};
export const graphs = {
  graphChartType,
  graphColorScale,
  graphData,
  graphDesc,
  graphDisplayOptions,
  graphTitle,
  updateRenderer
};
export const requestInfo = {};
export const propertySpatialReference = 1234;
export const orgSpatialReference = 1234;
